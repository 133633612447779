<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <CDialog title="批量修改仓库" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item label="入库仓库" prop="inStoreId">
            <SelectRemote v-model="form.inStoreId" :option="
                  $select({
                    key: 'listStore'
                  }).option
                " />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </template>
    </CDialog>
  </div>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import TablePage from "@/components/tablePage"; //表格页面组件
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import {
  listBillDetail,
  sumListBillDetail,
  generateInStore,
  modifyInStore
} from "@/api/mes/bill/processFinish";
export default {
  name: "processFinish",
  components: { TablePage, CDialog,SelectRemote },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      //新增表单校验
      rules: {
        inStoreId: [
          {
            required: true,
            message: "入库仓库不能为空",
            trigger: ["blur", "change"],
          }
        ],
      },
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listBillDetail,
        getSummaryApi: sumListBillDetail,
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/mes/bill/work/order/exportListBillDetail',
          exportName: '生产工序完成导出'
        },
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59'}),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '单据编号' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
            ]
          },
          this.$select({
            key: "bill_status",
            option: {
              seniorSearch: true,
              filter: "includeBillStatus",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listProduceDept",
            option: {
              type: "remote",
              label: "生产工厂",
              filter: "produceDeptIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            type: "local",
            label: "任务状态",
            model: "",
            filter: "taskStatus",
            seniorSearch: true,
            option: {
              clearable: true,
              multiple: true,
              data: [
                {
                  label: "开工",
                  value: 1,
                },
                {
                  label: "生产中",
                  value: 2,
                },
                {
                  label: "生产完成",
                  value: 3,
                },
                {
                  label: "已入库",
                  value: 4,
                }
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "generateDoc",
            label: "生成产品入库单",
            type: "primary",
          },
          {
            click: "batchUpDateStore",
            label: "批量修改仓库",
            type: "primary",
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "工单单号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "工单日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "planBillNo",
            label: "生产计划单",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "planBegTime",
            label: "开工时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "planEndTime",
            label: "完工时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "inStoreName",
            label: "入库仓库",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "categoryName",
            label: "商品类别",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "商品规格",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitSalePrice",
            label: "单价",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitMoney",
            label: "金额",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          // {
          //   prop: "createBy",
          //   label: "创建人",
          //   minWidth: 120,
          //   align: "center",
          // },
          // {
          //   prop: "createTime",
          //   label: "创建日期",
          //   minWidth: 155,
          //   align: "center",
          // },
          // {
          //   prop: "updateBy",
          //   label: "修改人",
          //   minWidth: 120,
          //   align: "center",
          // },
          // {
          //   prop: "updateTime",
          //   label: "修改日期",
          //   minWidth: 155,
          //   align: "center",
          // },
        ],
        list: [],
      },
    };
  },
  methods: {
    async submit() {
      try {
        await this.$refs["ruleForm"].validate();
        let billIds = this.options.check.map(x => ({ billDetailId: x.billDetailId, goodsName: x.goodsName, inStoreId: this.form.inStoreId }))
        await modifyInStore(billIds)
        this.cancel();
        this.$refs.tablePage.getList();
        this.$message.success("批量修改仓库成功");
      } catch (error) {
        console.log('报错', error);
      }
    },
    cancel() {
      this.dialogFormVisible = false;
      this.reset();
    },
    reset() {
      this.form = {};
      this.resetForm("ruleForm");
    },
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "generateDoc":
          {
            billIds = this.options.check.map(x => ({ billDetailId: x.billDetailId, goodsName: x.goodsName }))
            await generateInStore(billIds)
            await this.$refs.tablePage.getList();
            this.$message.success("生成产品入库单成功");
          }
          break;
        case "batchUpDateStore":
          {
            this.dialogFormVisible = true;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
