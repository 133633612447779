var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isDetail
        ? _c("TablePageDetail", {
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          })
        : _vm._e(),
      !_vm.isDetail
        ? _c("TablePage", {
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options2,
              callback: function ($$v) {
                _vm.options2 = $$v
              },
              expression: "options2",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }