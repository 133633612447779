<template>
  <div>
    <TablePageDetail v-model="options" @handleEvent="handleEvent" v-if="isDetail"/>
    <TablePage v-model="options2" @handleEvent="handleEvent" v-if="!isDetail"/>
  </div>
</template>

<script>
import { fcount, deepCopy } from "@/utils";
import {
  addProcessReport,
  getProcessReportInfo,
  modifyProcessReport,
  modifyBillStatus,
  listCandidateGoods
} from "@/api/mes/bill/processReport";
export default {
  name: 'processReportDetail',
  components: {
    TablePageDetail: () => import('@/components/tablePageDetail'),
    TablePage: () => import('@/components/tablePage')
  },
  data() {
    return {
      isDetail: true,
      options: {
        buttons: {
          isImportOrderBtn: true,
        },
        topQuery: {
          isProcess: true
        },
        billType: 200105,
        rowKey: "billId",
        billId: this.$route.query.billId || '',
        getQuitPath: "/mes/bill/processReport",
        addApi: addProcessReport,
        detailApi: getProcessReportInfo,
        updeteApi: modifyProcessReport,
        updeteStatusApi: modifyBillStatus,
        form: {
          billStatus: "",
          details: [],
        },
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            type: 'remoteSelect',
            showItem: {
              optionId: "goodsId",
              optionName: "goodsNo",
              prop: 'goodsId',
              label: 'goodsNo',
            },
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  // remoteBody: { },
                  change: (propValue, row, selectRow) => {
                    this.optionsList(selectRow.curTableIndex, [row], true);
                  },
                  tableChange: (propValue, row, selectRow) => {
                    this.optionsList(row.curTableIndex, row.check, false);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({
                          key: "goods",
                        }),
                      },
                    },
                  ],
                },
              },
            }).option,
            minWidth: 180,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
            align: "center",
            type: 'localSelect',
            option: {
              rowDataKey: "units",
              label: "unitName",
              value: "unitId",
              filterable: true,
              change: (propValue, row, selectRow) => {
                this.numUnit(selectRow.curTableIndex, row);
              },
            },
            // formatter: (v, row) => row?.goodsInfo?.unitName,
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 100,
            align: "center",
            type: 'my-input',
            inputType: 'number',
          },
          {
            prop: "unitSalePrice",
            label: "售价",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitMoney",
            label: "金额",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 100,
            align: "center",
            type: 'my-input',
          },
        ],
        summary: ["unitQty", "unitMoney"]
      },
      options2: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listCandidateGoods,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billDetailId",
        //搜索
        search: [
          {
            label: "日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '单据编号' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
            ]
          },
          this.$select({
            key: "listProduceDept",
            option: {
              type: "remote",
              label: "生产工厂",
              filter: "produceDeptIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "importSource",
            label: "确认",
            type: "primary",
          },
          {
            click: "getQuit",
            label: "返回",
            type: "primary",
            noRule: true
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "goodsNo",
            label: "商品编号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "商品规格",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "processName",
            label: "生产工序",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "nextProcessName",
            label: "下一生产工序",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          }
        ],
        list: [],
      },
    };
  },
  methods: {
    optionsList(tableIndex, row, isLine = false) {
      row = row.map(v => ({ ...v, unitSalePrice: v.salePrice, unitPurPrice: v.purPrice, unitMoney: 0, lineType: 0 }))
      if (isLine) {
        this.$set(this.options.form.details, tableIndex, row[0])
      } else {
        this.options.form.details.splice(tableIndex, 0, ...row);
        this.options.form.details = this.options.form.details.filter(item => item.goodsId != undefined)
      }
    },
    numUnit(tableIndex, row) {
      let newVal = deepCopy(this.options.form.details[tableIndex])
      newVal = {
        ...newVal,
        unitId: row.unitId,
        unitName: row.unitName,
        unitSalePrice: row.salePrice,
        unitPurPrice: row.purPrice,
        unitMoney: fcount([row.salePrice, (newVal.unitQty || 0)], '*'),
      }
      this.$set(this.options.form.details, tableIndex, newVal)
    },
    async handleEvent(type, row, rowIndex) {
      switch (type) {
        case "inputChange":
          if (row.prop == 'unitQty') {
            let rowNew = this.options.form.details[rowIndex]
            let unitMoney = fcount([rowNew.unitSalePrice, rowNew.unitQty], '*')
            this.$set(rowNew, 'unitMoney', unitMoney)
          }
          break;
        case "importOrder":
          this.isDetail = false;
          break;
        case "importSource":
          this.options2.check = this.options2.check.map(item => ({ ...item, processTaskBillDetailId: item.billDetailId, lineType: 1 }))
          this.options.form.details = deepCopy(this.options2.check)
          this.isDetail = true;
          this.options2.check = [];
          break;
        case "getQuit":
          this.isDetail = true;
          this.options2.check = [];
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
